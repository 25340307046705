import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'

interface LottieAnimationProps {
  animationName: string
}

const LottieAnimation = ({ animationName }: LottieAnimationProps) => {
  const [animationData, setAnimationData] = useState()

  useEffect(() => {
    import(`./../animations/lottie/${animationName}`).then(setAnimationData)
  }, [])

  if (!animationData) {
    return (
      <img
        tw='w-full h-full'
        src='data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw'
      />
    )
  }

  return <Lottie tw='w-full h-full' animationData={animationData} play loop={true} />
}

export default LottieAnimation
