import { motion } from 'framer-motion'
import React from 'react'
import tw from 'twin.macro'

interface AnimatedHeroTitleProps {
  title: string
}

const StyledTitle = tw.h2`text-3xl sm:text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl font-bold mb-4 lg:mb-8 uppercase text-grayP text-center lg:text-left`

const titleVariants = {
  hidden: {
    opacity: 0,
    x: -20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
    },
  },
}

const AnimatedHeroTitle = ({ title }: AnimatedHeroTitleProps) => {
  return (
    <motion.div variants={titleVariants}>
      <StyledTitle>{title}</StyledTitle>
    </motion.div>
  )
}

export default AnimatedHeroTitle
