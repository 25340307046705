import { Link } from 'gatsby'
import React from 'react'

interface Props {
  title: string
}

const ContactUsCta = ({ title }: Props) => {
  return (
    <Link
      tw='inline-flex items-center justify-center py-3 px-8 rounded-sm transition duration-500 ease-in-out uppercase hover:scale-105 bg-yellowP text-grayP hover:text-white animate-bounce'
      to='/contact'
    >
      {title}
    </Link>
  )
}

export default ContactUsCta
