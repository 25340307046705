import { motion } from 'framer-motion'
import React from 'react'
import ContactUsCta from '../ContactUsCta/ContactUsCta'
import LottieAnimation from '../LottieAnimation'
import MaxWidthContainer from '../MaxWidthContainer'
import ParticlesContainer from '../ParticlesContainer'
import AnimatedHeroTitle from './AnimatedHeroTitle'

const categories = ['Events', 'Consulting', 'Development']

const heroTitleContainer = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
    },
  },
}

const Hero = () => {
  return (
    <MaxWidthContainer>
      <div tw='flex flex-col h-[calc(100vh-81px)] relative'>
        <div tw='flex flex-wrap my-16'>
          <div tw='flex flex-col w-full lg:w-1/2 z-10'>
            <motion.div tw='z-10' initial='hidden' animate='visible' variants={heroTitleContainer}>
              {categories.map((category: string, index: number) => (
                <AnimatedHeroTitle key={index} title={category} />
              ))}
            </motion.div>
            <motion.h1
              tw='py-12 lg:py-20 leading-normal lg:text-xl text-center lg:text-left text-grayP'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.8 } }}
            >
              We deliver excellence and break borders of technology. We open up the blockchain/DLT
              space for you & your business in your domain.
              <br />
              <br />
              We are currently redesigning our website for you so that you can get to know us even
              better. Stay tuned!
            </motion.h1>
            <div tw='flex justify-center lg:justify-start'>
              <ContactUsCta title='Get in touch' />
            </div>
          </div>
          <div tw='w-full lg:w-1/2 z-10'>
            <div tw='flex justify-center'>
              <div tw='w-full h-64 md:h-80 lg:h-[550px]'>
                <LottieAnimation animationName='rocket.json' />
              </div>
            </div>
          </div>
        </div>
        {/*
          <div tw='flex justify-center mt-4 md:mt-12 lg:mt-32 mb-12 md:mb-24'>
            <ScrollMeButton />
          </div>
        */}
        <ParticlesContainer id='headerParticles' color='#2fb5b0' />
      </div>
    </MaxWidthContainer>
  )
}

export default Hero
