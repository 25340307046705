import React from 'react'
import Hero from '../components/Hero/Hero'
import Layout from '../components/Layout'

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      {/*
          <WhatWeDoSection />
          <EventsTwoColumnSection />
          <ConsultingTwoColumnSection />
          <DevelopmentTwoColumnSection />
          <KeyFiguresSection />
          <TechnologiesSection />
          <TestimonialsSection />
          <PartnerSection />
          <ContactUsCtaSection />
        */}
    </Layout>
  )
}

export default IndexPage

export { Head } from './../components/Head'
